<template>
	<div
		class="size-px-14 mt-50"
	>
		<div class="pa-20 justify-space-between items-center under-line size-px-16 ">
			<div class="word-break">{{ item_cartel.cartl_name }}</div>
			<button
				@click="onCartel"
			><v-icon>mdi-chevron-down</v-icon></button>
		</div>
		<div class="pa-20 size-px-16">
			<div class="pa-20 bg-gray-light radius-20">
				<div class="justify-space-between">
					<div></div>
					<div>
						<div class="size-px-13 justify-end gap-10 items-center">{{ summary_time }} <v-icon small @click="refresh">mdi-refresh</v-icon></div>
						<div class="mt-10"><span class="size-px-13">예상 수익</span> <span class="color-blue02 font-weight-bold ">{{ item_summary.tot_expectation_revenues | makeComma}}원</span></div>
					</div>
				</div>
				<hr class="mt-15 under-line ">
				<div class="mt-15">
					<div class="justify-space-between">
						<div class="size-px-13">구독 예상 수익</div>
						<div class="color-blue02 font-weight-700">{{ item_summary.subscrp_expectation_revenues | makeComma}}원</div>
					</div>
					<div class="justify-space-between">
						<div  class="size-px-13">후원 예상 수익</div>
						<div class="color-blue02 font-weight-700">{{ item_summary.spnsh_expectation_revenues | makeComma}}원</div>
					</div>
				</div>
			</div>
			<div class="mt-20 btn_wrap">
				<button
					class="btn_l btn_fill_blue"
					@click="onCalculate"
				>정산 신청</button>
			</div>
			<div class="mt-20 btn_wrap justify-space-between gap-10">
				<button
					class="btn_l "
					:class="view_type == 'subscribe' ? 'btn_fill_blue' : 'btn_gray'"
					@click="setType('subscribe')"
				>구독 정산 리포트</button>
				<button
					class="btn_l "
					:class="view_type == 'support' ? 'btn_fill_blue' : 'btn_gray'"
					@click="setType('support')"
				>후원 정산 리포트트</button>
			</div>
		</div>
		<div>
			<div>
				<DatePicker class="mt-20" type="year" :date="item_search.receive_applct_year" @click="setDate($event)" ></DatePicker>
			</div>
			<div></div>
		</div>
		<div>
			<ul
				v-if="items.length > 0"
			>
				<li
					v-for="(item, index) in items_list"
					:key="'item_' + index"

					class="justify-space-between-in pa-20 font-weight-500 "
				>
					<div>
						정산금액
						<div class="color-blue02 size-px-16 font-weight-700">{{ item.receive_schedule_amount |makeComma }}원</div>
					</div>
					<div class="mt-10">
						신청일
						<div>{{ item.receive_applctdate }}</div>
					</div>
					<div class="mt-10">
						입금일
						<div>{{ item.receive_compdt }}</div>
					</div>
					<div class="mt-10 not-align-center">
						입금계좌
						<div class="text-right">{{ item.finaccom_name}} {{ item.finac_info}} <br/> {{ item.finac_owner_name}}</div>
					</div>
					<div class="mt-10">
						상태
						<div><span  :class="'color-' + item.state_color">{{ item.receive_state_name }}</span> <span v-if="item.receive_state_code == 'SE00600004'">(고객센터 문의)</span></div>
					</div>
					<div class="mt-20 btn_wrap under-line pb-20">
						<button
							class="btn_m btn_outline_blue"
							@click="onCancel(item)"
							:disabled="!item.is_cancel"
						>정산신청취소</button>
						<button
							class="btn_m btn_outline_blue"
							@click="onDetail(item)"
						>상세정보보기</button>
					</div>
				</li>
			</ul>
			<Empty
				v-else
				class="mt-50"
			></Empty>
		</div>

		<PopupHalf
			v-if="is_on_cartel"
			:user="user"
			:title="$language.reset.cartel_list"

			@cancel="is_on_cartel = false"
		>
			<template
				v-slot:half_items
			>
				<template
					v-if="cartel_list.length > 0"
				>
					<li
						v-for="(cartel, c_index) in items_cartel"
						:key="'cartel_' + c_index"
						class="pb-10"
						@click="setCartel(cartel)"
					>{{ cartel.cartl_name }}</li>
				</template>
				<Empty
					v-else
				></Empty>
			</template>
		</PopupHalf>

		<PopupHalf
			v-if="is_on_calculate"
			:user="user"

			@cancel="is_on_calculate = false"
		>
			<template
				v-slot:half_items
			>
				<li
					v-for="(calc, c_index) in items_calculate"
					:key="'calc_' + c_index"
					class="pb-10"
					@click="toCalculate(calc)"
				>{{ calc.name }}</li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_cancel"

			@cancel="is_on_cancel = false"
			@click="postCancel"
		>
			<template v-slot:title>정산 취소 신청</template>
			<template
				v-slot:sub-txt
			>
				정산 신청을 취소 하시겠습니까?
			</template>
		</Popup_confirm>

		<PopupLayer
			v-if="is_on_detail && view_type == 'subscribe'"
		>
			<template
				v-slot:body
			>
				<div class="bg-white full-height flex-column  radius-20 overflow-hidden">
					<div class=" prl-20">
						<h3 class="pop_tit  text-center under-line mt-30 pb-20">구독 정산 정보</h3>
					</div>
					<div class="pa-20 full-height overflow-y-auto">
						<div>
							<div class="font-weight-700">정보</div>
							<div class="mt-15 justify-space-between-in">
								<div>
									카르텔
									<div class="color-blue02 size-px-16 font-weight-700">{{ item_cartel.cartl_name}} </div>
								</div>
								<div class="mt-10">
									신청일
									<div class="font-weight-500">{{ item_detail.receive_applctdate }}</div>
								</div>
								<div class="mt-10">
									입금일
									<div class="font-weight-500">{{ item_detail.receive_compdt }}</div>
								</div>
								<div class="mt-10 not-align-center">
									입금계좌
									<div class="text-right font-weight-500">{{ item_detail.finaccom_name}} {{ item_detail.finac_info}} <br/> {{ item_detail.finac_owner_name}}</div>
								</div>
								<div class="mt-10">
									상태
									<div :class="'color-' + item_detail.state_color" class="font-weight-500">{{ item_detail.receive_state_name }}</div>
								</div>
							</div>
						</div>
						<div class="mt-20 top-line pt-20">
							<div class="font-weight-700">정산 정보</div>
							<div class="mt-15 justify-space-between-in ">
								<div>
									정산 신청 금액
									<div class="font-weight-600">{{ item_detail.settle_applct_amount |makeComma }}원</div>
								</div>
								<div class="mt-10">
									수수료({{ item_detail.subscrp_plan_commis_rate }}%)
									<div class="font-weight-600">{{ item_detail.subscrp_plan_commis | makeComma }}원</div>
								</div>
								<div class="mt-10">
									부가세({{ item_detail.additional_tax_rate }}%)
									<div class="font-weight-600">{{ item_detail.additional_tax | makeComma }}원</div>
								</div>
								<div class="mt-10 not-align-center">
									출금수수료
									<div class="text-right font-weight-600">{{ item_detail.withdrawal_commis | makeComma }}원</div>
								</div>
								<div class="mt-10 not-align-center">
									최종정산금액
									<div class="color-blue02 size-px-16 font-weight-700">{{ item_detail.receive_schedule_amount | makeComma }}원</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="mt-auto btn_wrap justify-space-between"
					>
						<button
							class=" btn_fill_blue"
							@click="is_on_detail = false"
						>{{ $language.common.ok }} <!-- 확인 --></button>
					</div>
				</div>
			</template>

		</PopupLayer>

		<PopupLayer
			v-if="is_on_detail && view_type == 'support'"
		>
			<template
				v-slot:body
			>
				<div class="bg-white full-height flex-column  radius-20 overflow-hidden">
					<div class=" prl-20">
						<h3 class="pop_tit  text-center under-line mt-30 pb-20">후원 정산 정보</h3>
					</div>
					<div class="pa-20 full-height overflow-y-auto">
						<div>
							<div class="font-weight-700">정보</div>
							<div class="mt-15 justify-space-between-in">
								<div>
									카르텔
									<div class="color-blue02 size-px-16 font-weight-700">{{ item_cartel.cartl_name}} </div>
								</div>
								<div class="mt-10">
									신청일
									<div class="font-weight-500">{{ item_detail.receive_applctdate }}</div>
								</div>
								<div class="mt-10">
									입금일
									<div class="font-weight-500">{{ item_detail.receive_compdt }}</div>
								</div>
								<div class="mt-10 not-align-center">
									입금계좌
									<div class="text-right font-weight-500">{{ item_detail.finaccom_name}} {{ item_detail.finac_info}} <br/> {{ item_detail.finac_owner_name}}</div>
								</div>
								<div class="mt-10">
									상태
									<div :class="'color-' + item_detail.state_color" class="font-weight-500">{{ item_detail.receive_state_name }}</div>
								</div>
							</div>
						</div>
						<div class="mt-20 top-line pt-20">
							<div class="font-weight-700">정산 정보</div>
							<div class="mt-15 justify-space-between-in ">
								<div>
									신청 수량
									<div class="font-weight-600">{{ item_detail.receive_applct_point |makeComma }}개</div>
								</div>
								<div class="mt-10">
									정산신청금액
									<div class="font-weight-600">{{ item_detail.point_conversion_amount |makeComma }}원</div>
								</div>
								<div class="mt-10">
									수수료({{ item_detail.spnsh_commis_rate }}%)
									<div class="font-weight-600">{{ item_detail.spnsh_commis | makeComma }}원</div>
								</div>
								<div class="mt-10">
									부가세({{ item_detail.additional_tax_rate }}%)
									<div class="font-weight-600">{{ item_detail.additional_tax | makeComma }}원</div>
								</div>
								<div class="mt-10 not-align-center">
									출금수수료
									<div class="text-right font-weight-600">{{ item_detail.withdrawal_commis | makeComma }}원</div>
								</div>
								<div class="mt-10 not-align-center">
									최종정산금액
									<div class="color-blue02 size-px-16 font-weight-700">{{ item_detail.receive_schedule_amount | makeComma }}원</div>
								</div>
							</div>
						</div>
					</div>
					<div
						class="mt-auto btn_wrap justify-space-between"
					>
						<button
							class=" btn_fill_blue"
							@click="is_on_detail = false"
						>{{ $language.common.ok }} <!-- 확인 --></button>
					</div>
				</div>
			</template>

		</PopupLayer>
	</div>
</template>

<script>
	import PopupHalf from "@/view/Layout/PopupHalf";
	import DatePicker from "@/components/DatePicker";
	import Empty from "@/view/Layout/Empty";
	import Popup_confirm from "@/view/Layout/PopupConfirm";
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'CalculateReport'
		,
		components: {PopupLayer, Popup_confirm, Empty, DatePicker, PopupHalf},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: 'CalculateReport'
					, title: '카르텔 정산'
					, type: 'mypage'
					, not_footer: true
				}
				, item_search:{
					page_number: 1
					, pagerecnum: 100
					, cartl_number: ''
					, receive_applct_year: this.$date.init().year + ''
					, receive_state_code: ''
					// SE00600001 입금 신청 중
					// SE00600002 입금 처리 중
					// SE00600003 입금 완료
					// SE00600004 입금 거부
				}
				, items: []
				, item_calculate: {

				}
				, items_cartel: []
				, item_cartel: {

				}
				, item_summary: {

				}
				, summary_time: this.$date.now()
				, is_on_cartel: false
				, is_on_calculate: false
				, items_calculate: [
					{ name: '구독 정산', code: 'subscribe'}
					, { name: '후원 정산', code: 'support'}
				]
				, is_on_detail: false
				, item_detail: {

				}
				, is_on_cancel: false
				, item_cancel: {

				}
				, view_type: 'subscribe'
			}
		}
		, computed: {
			items_list: function(){
				return this.items.filter( (item) => {
					switch (item.receive_state_code){
						case 'SE00600001':
							item.state_color = ''
							item.is_cancel = true
							break;
						case 'SE00600002':
							item.state_color = ''
							break;
						case 'SE00600003':
							item.state_color = 'blue'
							break;
						case 'SE00600004':
							item.state_color = 'red'
							break;
					}
					return item
				})
			}
			, cartel_list: function(){
				return this.items_cartel.filter( (item) => {
					return item
				})
			}
		}
		, methods: {
			getData: async function(){
				try {
					this.items = []
					let url = this.$api_url.api_path.get_calculate_list
					if(this.view_type == 'support'){
						url = this.$api_url.api_path.get_calculate_support_list
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_search.cartl_number
							, receive_applct_year: this.item_search.receive_applct_year
							, receive_state_code: this.item_search.receive_state_code
						}
						, type: true
					})

					if(result.success){
						if(this.view_type == 'subscribe'){
							this.items = result.data.subscrp_settle_particulars_list
						}else{
							this.items = result.data.spnsh_settle_particulars_list
						}
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, getMyCartel: async function(){

				try {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance_cartel
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})

					if(result.success){
						if(result.data.settle_target_cartl_list.length > 0){

							this.items_cartel = result.data.settle_target_cartl_list
							this.item_cartel = this.items_cartel[0]
							this.items_cartel.filter( (item) => {
								if(item.cartl_number == '31238667920b24d'){
									this.item_cartel = item
								}
							})

							this.item_search.cartl_number = this.item_cartel.cartl_number
							await this.getMyInfo()
						}else{
							this.$bus.$emit('notify', { type: 'error', message: '등록된 카르텔이 없습니다.'})
							this.$emit('goBack')
						}
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, getMyInfo: async function(){

				try {
					this.item_summary = {}
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_finance_expect
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_search.cartl_number
						}
						, type: true
					})

					if(result.success){
						this.item_summary = result.data

						await this.getData()
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, onCartel: function(){
				this.is_on_cartel = true
			}
			, setCartel: function(item){
				this.item_cartel = item
				this.is_on_cartel = false
				this.item_search.cartl_number = item.cartl_number

				this.getMyInfo()
			}
			, setDate: function(year){
				this.item_search.receive_applct_year = year + ''
				this.getData()
			}
			, onCalculate: function(){
				this.is_on_calculate = true
			}
			, toCalculate: function(calc){
				if(calc.code == 'subscribe'){
					this.$bus.$emit('to', { name: 'CalculateItem' , params: { idx: this.item_cartel.cartl_number, type: calc.code}})
				}else{
					this.$bus.$emit('to', { name: 'CalculateItemSupport' , params: { idx: this.item_cartel.cartl_number, type: calc.code}})
				}
			}
			, onDetail:  async function(item){
				try {
					let url = this.$api_url.api_path.get_calculate
					if(this.view_type == 'support'){
						url = this.$api_url.api_path.get_calculate_support
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_cartel.cartl_number
							, settle_receive_applct_number: item.settle_receive_applct_number
							, point_receive_applct_number: item.point_receive_applct_number
						}
						, type: true
					})

					if(result.success){
						this.item_detail = result.data
						switch (this.item_detail.receive_state_code){
							case 'SE00600001':
								this.item_detail.state_color = ''
								break;
							case 'SE00600002':
								this.item_detail.state_color = ''
								break;
							case 'SE00600003':
								this.item_detail.state_color = 'blue'
								break;
							case 'SE00600004':
								this.item_detail.state_color = 'red'
								break;
						}
						this.is_on_detail = true
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, setType: function(type){
				this.view_type = type
				this.getData()
			}
			, onCancel: function(item){
				this.item_cancel = item
				this.is_on_cancel = true
			}
			, postCancel: async function(){
				try {
					let url = this.$api_url.api_path.delete_calculate
					if(this.view_type == 'support'){
						url = this.$api_url.api_path.delete_calculate_support
					}
					const result = await this.$Request({
						method: 'post'
						, url: url
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.item_search.cartl_number
							, settle_receive_applct_number: this.item_cancel.settle_receive_applct_number
							, point_receive_applct_number: this.item_cancel.point_receive_applct_number
						}
						, type: true
					})

					if(result.success){
						this.is_on_cancel = false
						this.item_cancel = {}
						await this.getMyInfo()
					}else{
						throw result.message
					}
				}catch (e){
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}
			}
			, refresh: function(){
				this.getMyInfo()
			}
		}
		, async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getMyCartel()

		}
	}
</script>